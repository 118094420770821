@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root{
  --primaryColor: #263C57;
  --secundaryColor: #7FB514;
  --secundaryPale: #70991f;
  --textSecundary: #7c7c7c;
  --basePrimary: #201e1e;
  --texturaDourada: linear-gradient(90deg, #7A6013, #B89232, #E9C778, #C2A036, #81661A);
  --dourada: #C2A036;
  --textoWhite: #FFFFFF;
  --amarelodecisao: #ffc60b;
}

body{
  margin:0px;
  padding: 0px;
  background-color: #FFFFFF;
  font-family: "Montserrat", sans-serif;
  
}
*{
  box-sizing: border-box;

}
ul{
  list-style: none;
}
a{
  text-decoration: none;
}
button{
  outline: none;
  border: none;
}
input{
  outline: none;
  border: none;
}
h1{
  font-family: "Libre Baskerville", serif;
}

.slider {
  width:100%;
  height:920px;
  margin: 0 auto;
  overflow: hidden;
}
.slides{
  width:400%;
  height:920px;
  display: flex;
}
.slides input{
  display: none;

}
.slide{
  width: 25%;
  position: relative;
  transition: 1s;
}
.slide img{
  width:100%;
  height:920px;

  cursor: pointer;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 0;
  
}
.slide::before{
  position: absolute;
  content: "";
  background: -moz-radial-gradient(center, ellipse cover,#201e1e, rgba(0, 0, 0, 0.6) 100%);
  background: -webkit-radial-gradient(center, ellipse cover,#201e1e, rgba(0, 0, 0, 0.6) 100%);
  background: radial-gradient(ellipse at center,#201e1e, rgba(0, 0, 0, 0.8) 20%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#61b50808', endColorstr='#99000000',GradientType=1 );
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  
}

.manual-navigation{
  position: absolute;
  width: 100%;
  margin-top: -80px;
  display: flex;
  justify-content: center;
  z-index: 3;
}
.manual-btn{
  border: 2px solid #FFFFFF;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  transition: 1s;
}
.manual-btn:not(:last-child){
  margin-right: 40px;
}
.manual-btn:hover{
  background-color: #FFFFFF;
}

#radio1:checked ~ .first{
  margin-left: 0;
}
#radio2:checked ~ .first{
  margin-left: -25%;
}
#radio3:checked ~ .first{
  margin-left: -50%;
}
#radio4:checked ~ .first{
  margin-left: -75%;
}

.navigation-auto div{
  border: 2px solid var(--basePrimary);
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  transition: 1s;
}

.navigation-auto{
  position: absolute;
  width: 100%;
  margin-top: 840px;
  display: flex;
  justify-content: center;
  z-index: 2;
}
.navigation-auto div:not(:last-child){
  margin-right: 40px;
}
#radio1:checked ~ .navigation-auto .auto-btn1{
  background-color: #FFFFFF;
}
#radio2:checked ~ .navigation-auto .auto-btn2{
  background-color: #FFFFFF;
}
#radio3:checked ~ .navigation-auto .auto-btn3{
  background-color: #FFFFFF;
}
#radio4:checked ~ .navigation-auto .auto-btn4{
  background-color: #FFFFFF;
}

.title{
  text-align: center;
  padding: 50px 0 0px;
}
.title h1{
  margin: 0;
  padding: 0;
  font-size: 2rem;
  color:var(--basePrimary);
  font-weight: 600;
  
}

.logo img{
  width: 250px;
  margin-left: 100px;
}
#main{
  width:100%;
  height:920px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 2;
  
}
#main::before{
  position: absolute;
  content: "";
  background: -moz-radial-gradient(center, ellipse cover,#201e1e, rgba(0, 0, 0, 0.6) 100%);
  background: -webkit-radial-gradient(center, ellipse cover,#201e1e, rgba(0, 0, 0, 0.6) 100%);
  background: radial-gradient(ellipse at center,#201e1e, rgba(0, 0, 0, 0.4) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#61b50808', endColorstr='#99000000',GradientType=2 );
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  
}
#main::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px; 
  background: var(--texturaDourada); 
  z-index: 4;
  pointer-events: none;
  
}
nav{
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width:100%;
  height: 150px;
  z-index: 3;
  background-color: var(--amarelodecisao);
  transition: 0.6s ease-in-out;
  
  
  
}

nav.active{
  box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.157);
  background-color: var(--amarelodecisao);
  border-bottom: 2px solid var(--texturaDourada);
  z-index: 4;
}

nav.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px; 
  background: #f70000; 
  z-index: 4;
  pointer-events: none;
}



nav ul{
  display: flex;
}
nav img{
  cursor: pointer;
}
.active{
  background-color: var(--basePrimary);
  color:#000000;
  box-shadow: 2px 4px 10px var(--basePrimary);
  transition: all ease 0.2s;
}
.active:hover{
  
  color:#000000;
  box-shadow: 2px 4px 10px var(--basePrimary);
  transition: all ease 0.2s;
}
nav ul li a{
  
  height:40px;
  line-height: 43px;
  margin: 3px;
  padding: 0px 22px;
  display: flex;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 500;
  color:#000000;
  letter-spacing: 1px;
  border-radius: 3px;
  transition: 0.2s ease-in-out;
  cursor: pointer;

  position: relative;
}

nav ul li a::after {
  /* Se não precisar de um segundo efeito, pode deixar o ::after vazio ou removê-lo */
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: transparent; /* Deixe transparente ou remova se não for necessário */
  transition: width 0.3s ease-in-out;
}

nav ul li a:hover::after {
  width: 75%;
  left: 11%;
}

nav ul li a:hover{
  color:var(--dourada);  
}

nav ul li a::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 10%;
  width: 0%;
  height: 3px; /* Altura da linha */
  background-color: var(--dourada); /* Cor da linha ao passar o mouse */
  transition: width 0.5s ease-in-out; /* Transição suave para a linha crescer */
  border-radius: 2rem; /* Bordas arredondadas da linha */
}

nav ul li a:hover::before {
  width: 80%; /* A linha cresce de 0% a 100% no hover */
}


.dropdown {
  position: relative;
  display: inline-block;
  
}

.dropdown-menu {
  display: block;
  position: absolute;
  background-color: var(--basePrimary);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.6);
  min-width: 250px;
  z-index: 5;
  left: 0;
  padding: 0;
  border-radius: 10px;
  
  opacity: 0;
  transform: translateY(-10px); /* Move para cima inicialmente */
  transition: opacity 0.4s ease, transform 0.4s ease; /* Animação de suavidade */
  pointer-events: none; /* Desabilita interação quando escondido */
}

.dropdown-menu li {
  padding: 2px 0px;
  left: 0;
  list-style: none; 
  text-align: left;
  
}

.dropdown-menu li a {
  color: #FFFFFF;
  text-decoration: none;
  display: block;
  font-size: 0.7rem;
}


/* Mostrar o dropdown ao passar o mouse */
.dropdown:hover .dropdown-menu {
  opacity: 1;
  transform: translateY(0); /* Volta para a posição original */
  pointer-events: auto; /* Habilita interação */
}


.header .link{
  position: relative;
  margin: 12px;
  padding-bottom: 3px;
  text-decoration: none;
  transition: all .8s;
}



.header .link::before{
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 3px;
  background-color: var(--basePrimary);
  transition: width .50s;
  
  border-bottom: 100px;
  
  outline: none;
  border: none;
  border-radius: 2rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  
}

.header .link:hover::before{
  width: 100%;
  
}


/* Estilo do PopUp */
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  background-color: var(--basePrimary);
  color: var(--textoWhite);
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  padding: 20px;
  text-align: center;
  z-index: 1000; /* Certifique-se de que o PopUp apareça acima de outros elementos */
}

.popup p {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.popup button {
  background-color: var(--dourada);
  color: var(--textoWhite);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.popup button:hover {
  background-color: var(--dourada);
}

/* Animação de entrada */
@keyframes popupFadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -60%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

.popup {
  animation: popupFadeIn 0.5s ease-in-out;
}



nav .menu-btn,
.menu-icon{
      display:none;
      
  }




.name{
  text-align: center;
  
  width: 1400px;
  position: absolute;
  left: 50%;
  top: 55%;
  transform: translate(-50%,-55%);
  z-index: 2;
}
.name span{
  font-family: "Libre Baskerville", serif;
    background: var(--amarelodecisao);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
  
  font-size: 4rem;
}
.name .details{
  font-size: 20px;
  color: #c5c5c5;
  font-family: "Libre Baskerville", serif;
}
.name h2{
  font-family: "Libre Baskerville", serif;
  font-size: 3.5rem;
  margin:0px;
  letter-spacing: 2px;
  color:#ffffff;
}
.name h1{
 /* font-family:'Lato';*/
  font-size: 5rem;
  margin:0px;
  letter-spacing: 2px;
  color:#ffffff;
}

.header-btns{
  display: flex;
  margin-top: 40px;
  margin-left: 40%;
}
.header-btn{
  width:160px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  background-color: var(--secundaryColor);
  box-shadow: 5px 10px 30px rgba(255, 2, 2, 0.336);
  border-radius: 5px;
  color:#ffffff;
}
.header-btn:hover{
  background-color: transparent;
  transition: all ease 0.5s;
  color: #ffffff;
  border: 2px solid var(--secundaryColor);
}

#features{
  width:100%;
  height:100vh;
  box-sizing: border-box;
  /*font-family:'Lato';*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--basePrimary);
}

#features::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px; 
  background: var(--texturaDourada); 
  z-index: 1;
  pointer-events: none;
  
}

#features h1{
  color: var(--textoWhite);
  font-size: 3rem;
  text-align: center;
  margin-top: -100px;
  margin-bottom: 100px;
  
}
.a-container{
  display: flex;
  justify-content: center;
  align-content: center;


}
.a-box{
  background-color: #FFFFFF;
  width: 270px;
  height: 500px;
  margin: 10px;
  border-radius: 5px;
  overflow: hidden;
  transition: 0.1s ease-in-out;
  position: relative;
  cursor: pointer;
}
.a-b-img{
  width: 100%;
  height: 50%;

}
.a-b-img img{
  padding: 0px;
  margin-top: 0;
  width:100%;
  height: 100%;

}
.a-box:hover{
  border: 1px ridge var(--dourada);
  box-shadow: 2px 2px 12px var(--basePrimary);
  
}
.a-box::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px; 
  background: var(--texturaDourada); 
  z-index: 1;
  pointer-events: none;
  
}
.a-b-text{
  width: 100%;
  height: 40%;
  background-color:#FFFFFF;
  display:flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.a-b-text h2{
  color: var(--basePrimary);
  margin-top: 0;
  
}
.a-b-text p{
  margin: 0;
  color:var(--textSecundary);
  font-size: 1.1rem;
 /* font-family:'Lato';*/
  display: block;
  display: -webkit-box;
  max-width: 80%;
  margin-top: 5px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  
}
.a-b-text p:hover{
  color:var(--basePrimary);
  -webkit-line-clamp: 13;
  
}
.a-b-text a{
  margin-top: 10px;
}

/* box da equipe*/


#equipe{
  width:100%;
  min-height: 120vh;
  height:100%;
  box-sizing: border-box;
  padding-top: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--basePrimary);
}

#equipe::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px; 
  background: var(--texturaDourada); 
  z-index: 1;
  pointer-events: none;
  
}

#equipe h1{
  color: var(--textoWhite);
  font-size: 3rem;
  text-align: center;
  margin-top: -100px;
  margin-bottom: 100px;
  
}
.a-container-equipe{
  display: flex;
  justify-content: center;
  align-content: center;


}
.a-box-equipe{
  background-color: #FFFFFF;
  width: 270px;
  height: 700px;
  margin: 10px;
  border-radius: 5px;
  overflow: hidden;
  transition: 0.1s ease-in-out;
  position: relative;
  cursor: pointer;
}
.a-b-img-equipe{
  width: 100%;
  height: 40%;

}
.a-b-img-equipe img{
  padding: 0px;
  margin-top: 0;
  width:100%;
  height: 100%;

}
.a-box-equipe:hover{
  border: 1px ridge var(--dourada);
  box-shadow: 2px 2px 12px var(--basePrimary);
  
}
.a-box-equipe::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px; 
  background: var(--texturaDourada); 
  z-index: 1;
  pointer-events: none;
  
}
.a-b-text-equipe{
  width: 100%;
  height: 50%;
  background-color:#FFFFFF;
  display:flex;
  justify-content: center;
  align-items:center;
  text-align: center;
  flex-direction: column;
  top: 0;
  
}
.a-b-text-equipe h2{
  color: var(--basePrimary);
  margin-top: 0;
  
}
.a-b-text-equipe p{
  margin: 0;
  color: var(--textSecundary);
  font-size: 0.9rem;
  max-width: 92%;
  margin-top: 0px;
  overflow: visible; /* Permitir que o texto ocupe mais espaço se necessário */
  word-wrap: break-word; /* Quebra as palavras que não cabem na linha */
  white-space: normal; /* Garante que o texto não fique em uma única linha */
  text-overflow: clip; /* Remove o truncamento com "..." */
  margin-bottom: 0;
  padding-bottom: 0;
  text-align: start;
  
}

.a-b-text-equipe a{
  margin-top: 0px;
}




#presentaion{
  padding-top: 30px;
  width:100%;
  height:728px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  /*background-image: url('./Assets/contact.jpeg');*/
  background-color: var(--basePrimary);
  text-align: center;
  z-index: 2;
}



/*#presentaion::before{
  position: absolute;
  content: "";
  background: -moz-radial-gradient(center, ellipse cover,#7FB514, rgba(0, 0, 0, 0.6) 100%);
  background: -webkit-radial-gradient(center, ellipse cover,#7FB514, rgba(0, 0, 0, 0.6) 100%);
  background: radial-gradient(ellipse at center,#263C57, rgba(0, 0, 0, 0.4) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#61b50808', endColorstr='#99000000',GradientType=1 );
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}*/



.contact-info {
  margin-top: 30px;
}

.contact-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
}

.contact-item i {
  font-size: 1.5rem;
  color: #8b7d66; /* Dourado para ícones */
  margin-right: 10px;
}

.info-text {
  text-align: left;
}

.info-text h3 {
  font-size: 1rem;
  color: #8b7d66;
  font-weight: bold;
  margin-bottom: 5px;
}

.info-text p {
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 0;
}

/********************Atuacao contato***********************/


.containerFormAtuacao{
  width: 90%;
  max-width: 95%;
  height: auto;
  background: #fff;
  margin: 30px 200px;
  border: 1px solid #fff;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  overflow: hidden;
  float: right;
}

.containerFormAtuacao .right{
  float: right;
  width: 100%;
  height: 600px;
  box-sizing: border-box;
  border-radius: 5px;
}

.containerFormAtuacao .left{
  float: left;
  width: 50%;
  height: 600px;
  padding-bottom: 0;
  bottom: 0;
  background-size: cover;
  box-sizing: border-box;
  border-radius: 5px;
}

.formBoxAtuacao{
  width: 100%;
  height: 600px;
  padding: 40px 40px;
  box-sizing: border-box;
  background: #fff;
}


.formBoxAtuacao p{
  margin: 0;
  padding: 0;
  float: left;
  font-weight: bold;
  color: var(--basePrimary);

}
.formBoxAtuacao input{
  width: 100%;
  margin-bottom: 40px;
}
.formBoxAtuacao input[type="text"],
.formBoxAtuacao input[type="tel"],
.formBoxAtuacao input[type="email"]
{
  border: none;
  border-bottom: 2px solid var(--basePrimary);
  outline: none;
  height: 40px;
}

.formBoxAtuacao input[type="text"]:focus,
.formBoxAtuacao input[type="tel"]:focus,
.formBoxAtuacao input[type="email"]:focus
{
  border-bottom: 2px solid var(--dourada);
}





.formBoxAtuacao input[type="submit"]
{
  border: none;
  outline: none;
  height: 40px;
  color: #fff;
  background: #262626;
  cursor: pointer;

  
  border-radius: 10px;
  border: none;
  outline: none;
  color: #ffffff;
  background-color:var(--basePrimary);
  cursor: pointer;
}
.formBoxAtuacao input[type="submit"]:hover
{
  background: var(--basePrimary); 
  transition: all ease 0.3s;
  color: var(--dourada);
}

.formBoxAtuacao input[type="button"]
{
  border: none;
  outline: none;
  height: 34px;
  color: #fff;
  background: #262626;
  cursor: pointer;
  margin-top: 0;
  padding-top: 0;
  display: none;
  border-radius: 10px;
  border: none;
  outline: none;
  color: #ffffff;
  background-color:var(--basePrimary);
  cursor: pointer;
}
.formBoxAtuacao input[type="button"]:hover
{
  background: var(--basePrimary); 
  transition: all ease 0.3s;
  color: var(--dourada);
}


/**********************Fim Atuacao contato***************************/




.containerForm{
  width: 60%;
  max-width: 55%;
  height: auto;
  background: #fff;
  margin: 30px 400px;
  border: 1px solid #fff;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  overflow: hidden;
  float: right;
}

.containerForm .right{
  float: right;
  width: 45%;
  height: 600px;
  box-sizing: border-box;
  border-radius: 5px;
}

.containerForm .left{
  float: left;
  width: 50%;
  height: 600px;
  padding-bottom: 0;
  bottom: 0;
  background-size: cover;
  box-sizing: border-box;
  border-radius: 5px;
}

.formBox{
  width: 100%;
  height: 600px;
  padding: 40px 40px;
  box-sizing: border-box;
  background: #fff;
}


.formBox p{
  margin: 0;
  padding: 0;
  float: left;
  font-weight: bold;
  color: var(--basePrimary);

}
.formBox input{
  width: 100%;
  margin-bottom: 40px;
}
.formBox input[type="text"],
.formBox input[type="tel"],
.formBox input[type="email"]
{
  border: none;
  border-bottom: 2px solid var(--basePrimary);
  outline: none;
  height: 40px;
}

.formBox input[type="text"]:focus,
.formBox input[type="tel"]:focus,
.formBox input[type="email"]:focus
{
  border-bottom: 2px solid var(--dourada);
}





.formBox input[type="submit"]
{
  border: none;
  outline: none;
  height: 40px;
  color: #fff;
  background: #262626;
  cursor: pointer;

  
  border-radius: 10px;
  border: none;
  outline: none;
  color: #ffffff;
  background-color:var(--basePrimary);
  cursor: pointer;
}
.formBox input[type="submit"]:hover
{
  background: var(--basePrimary); 
  transition: all ease 0.3s;
  color: var(--dourada);
}

.formBox input[type="button"]
{
  border: none;
  outline: none;
  height: 34px;
  color: #fff;
  background: #262626;
  cursor: pointer;
  margin-top: 0;
  padding-top: 0;
  display: none;
  border-radius: 10px;
  border: none;
  outline: none;
  color: #ffffff;
  background-color:var(--basePrimary);
  cursor: pointer;
}
.formBox input[type="button"]:hover
{
  background: var(--dourada); 
  transition: all ease 0.3s;
  color: #ffffff;
}




/*----------------*/

.formBoxLetter{
  width: 70%;
  margin-top: 40px;
  margin-left: 150px;
  padding: 10px 10px;
  
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.1);
  
  border-radius: 5px;
}



.formBoxLetter p{
  margin: 0;
  padding: 0;
  float: left;
  font-size: 1.1rem;
margin-bottom: 10px;
  color: #FFFFFF;
  font-family: "Montserrat", sans-serif;


}

.formBoxLetter input{
  width: 100%;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0);
}

.formBoxLetter input[type="text"],
.formBoxLetter input[type="tel"],
.formBoxLetter input[type="email"]
{
  border: none;
  border-bottom: 2px solid var(--basePrimary);
  outline: none;
  height: 40px;
}

.formBoxLetter input[type="text"]:focus,
.formBoxLetter input[type="tel"]:focus,
.formBoxLetter input[type="email"]:focus
{
  border-bottom: 2px solid var(--dourada);
}

.ZapBox {
  display: flex;
  justify-content: center; /* Centraliza o botão */
  align-items: center;
  margin-top: 20px; /* Ajuste conforme necessário */
}

.ZapBox button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--textoWhite); /* Cor do texto */
  background-color: #262626; /* Fundo do botão */
  border: none;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3); /* Sombra */
  padding: 10px 20px; /* Espaçamento interno do botão */
  font-size: 16px;
  cursor: pointer;
  transition: all ease 0.3s;
}

.ZapBox .whatsapp-icon {
  font-size: 20px; /* Tamanho do ícone */
  margin-right: 8px; /* Espaço entre o ícone e o texto */
  color: var(--secundaryColor);
}

.ZapBox button:hover {
  background-color: var(--basePrimary); /* Cor de fundo ao passar o mouse */
  color: var(--textoWhite); /* Cor do texto ao passar o mouse */
}



.formBoxLetter input[type="submit"]
{
  border: none;
  outline: none;
  height: 40px;
  color: #fff;
  background: #262626;
  cursor: pointer;

  
  border-radius: 10px;
  border: none;
  outline: none;
  color: #ffffff;
  background-color:var(--basePrimary);
  cursor: pointer;
}
.formBoxLetter input[type="submit"]:hover
{
  background: var(--basePrimary); 
  transition: all ease 0.3s;
  color: var(--dourada);
}

.formBoxLetter input[type="button"]
{
  border: none;
  outline: none;
  height: 34px;
  color: #fff;
  background: #262626;
  cursor: pointer;
  margin-top: 0;
  padding-top: 0;
  display: none;
  border-radius: 10px;
  border: none;
  outline: none;
  color: #ffffff;
  background-color:var(--secundaryColor);
  cursor: pointer;
}
.formBoxLetter input[type="button"]:hover
{
  background: var(--secundaryPale); 
  transition: all ease 0.3s;
  color: #ffffff;
}



.pr-heading{
  text-align: center;
  /*font-family:'Lato';*/
  width: 600px;
  position: absolute;
  left: 50%;
  top: 55%;
  transform: translate(-50%,-55%);
}
.pr-heading span{
  color: var(--secundaryColor);
}
.pr-heading .details{
  font-size: 23px;
  color: #c5c5c5;
}
.pr-heading .details{
  font-size: 1.2rem;
}
.pr-heading h1{
 /* font-family:'Lato';*/
  font-size: 6rem;
  margin:0px;
  letter-spacing: 2px;
  color:#ffffff;
}
.pr-heading h2{
  /*font-family:'Lato';*/
  font-size: 3rem;
  margin:0px;
  letter-spacing: 2px;
  color:#ffffff;
}
.pr-btns{
  display: flex;
  margin-top: 40px;
  margin-left: 35%;
}
.pr-btn{
  width:160px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  background-color: var(--secundaryColor);
  box-shadow: 5px 10px 30px var(--secundaryPale);
  border-radius: 5px;
  color:#ffffff;
  transition: all ease 0.5s;
}
.pr-btn:hover{
  
  
  color: #ffffff;
  border: 2px solid var(--secundaryPale);
}

#about{
  
  width:100%;
  height: 90vh;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 5% 0px 5%;
  position: relative;
  background-color: #FFFFFF;
  z-index: 2;
}
#about::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px; 
  background: var(--texturaDourada); 
  z-index: 2;
  pointer-events: none;
  
}

.about-text{
  width: 45%;
  color: var(--basePrimary);
  list-style: circle;
}
.about-text h1{
  font-size: 3rem;
  color:var(--basePrimary);
  font-weight: 600;
  margin: 0px;
  padding: 0px;
  margin-top: -200px;
  margin-bottom: 50px;
}
.about-text p{
  width: 80%;
  font-size: 1.2rem;
  color:var(--textSecundary);
  text-align: justify;
}







#atuacao{
  
  width:100%;
  height: 90vh;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 5% 0px 5%;
  position: relative;
  background-color: #FFFFFF;
  z-index: 2;
}


.atuacao-text{
  width: 65%;
  color: var(--basePrimary);
  list-style: circle;
}
.atuacao-text h1{
  font-size: 3rem;
  color:var(--basePrimary);
  font-weight: 600;
  margin: 0px;
  padding: 0px;
  margin-top: -100px;
  margin-bottom: 50px;
}
.atuacao-text p{
  width: 60%;
  font-size: 1.2rem;
  color:var(--textSecundary);
  text-align: justify;
}


.atuacao-image{
  width: 50%;
}
.atuacao-image img{
width: 650px;
border-radius: 10px;
}
.atuacao-contato{
  width: 30%;
}

.contato-text p{
  width: 100%;
  font-size: 1.1rem;
  color:var(--textSecundary);
  text-align: justify;
  margin-left: 35px;
}
.contato-text h1{
  margin-top: 50px;
  font-size: 2rem;
  
}
.about-text button{
  margin-top: 20px;
  width: 140px;
  height: 45px;
  border-radius: 10px;
  border: none;
  outline: none;
  color: #ffffff;
  background-color:var(--secundaryColor);
  cursor: pointer;
}
.about-text button:hover{
  
  background: var(--secundaryPale);
  transition: all ease 0.3s;
  color: #ffffff;
}
.about-image{
  width: 50%;
  
  
}
.about-image img{
width: 650px;
height: 750px;
border-radius: 10px;
}

#contact{
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#contact h1{
  color: #fff;
  font-size: 3rem;
}
#contact form{
  width:600px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}
#contact form input,
#contact form textarea{
  width:100%;
  height:50px;
  margin:5px 0px;
  padding: 10px;
  border: none;
  outline: none;
  background-color: #ffffff2d;
  color: #ffffff;
  border-radius: 5px;
}
#contact form textarea{
  height: 150px;
}
#contact form input[type="submit"]{
  height: 45px;
  background: linear-gradient(90deg, #FF1414 20%,#f70000);
  color: #ffffff;
  text-transform: uppercase;
}
#foot {
  background-image: image-set("./Assets/facul.jpg");
  margin: 0;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed; /* Mantém o footer fixo */
  bottom: 0; /* Posiciona o footer no fundo da página */
  left: 0;
  width: 100%; /* O footer ocupará toda a largura */
  z-index: 1;
  min-height: 30vh;
}
#foot::before{
  position: absolute;
  content: "";
  background: -moz-radial-gradient(center, ellipse cover,#201e1e, rgba(0, 0, 0, 0.6) 100%);
  background: -webkit-radial-gradient(center, ellipse cover,#201e1e, rgba(0, 0, 0, 0.6) 100%);
  background: radial-gradient(ellipse at center,#201e1e, rgba(0, 0, 0, 0.4) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#61b50808', endColorstr='#99000000',GradientType=2 );
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  
}

#foot::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px; 
  background: var(--texturaDourada); 
  z-index: 1;
  pointer-events: none;
  
}

.about-imagebranco{
  min-height: 275px;
  background: var(--basePrimary);
}
.about-imagebrancobranco{
  min-height: 300px;
  background: #FFFFFF;
}
.about-imagebrancotopo{
  max-height: 350px;
  background: var(--basePrimary);
}

.page-content {
  transition: margin-left 0.6s ease-in-out, opacity 0.6s ease-in-out;
  opacity: 1;
  margin-left: 0;
}

.slide-in {
  opacity: 1;
  margin-left: 0;
}

.slide-out {
  opacity: 1;
  margin-left: -25px; /* Move o conteúdo para a esquerda sem usar transform */
}

.footer-wrapper {
  
  display: flex;
  
  
}
.footer-logo-container {
  max-width: 110px;
}
.footer-icons {
  margin-top: .5rem;
}
.footer-icons svg {
  font-size: 2.5rem;
  margin-right: 1.25rem;
  color: #000000;
}
.footer-icons svg:hover{
  font-size: 2.8rem;
  margin-right: 0.95rem;
  color:chartreuse ;
}
.footer-icons-two {
  margin-top: .5rem;
}
.footer-icons-two svg {
  font-size: 1.2rem;
  margin-right: 0.5rem;
  color: #FFFFFF;
}
/* .footer-section-one {
  border: 2px solid blue;
} */
.footer-section-one {
  flex: 1;
  display: flex;
  justify-content: center;
}

.footer-section-two {
  flex: 1;
  display: flex;
  justify-content: center;
}
.footer-section-center {
  flex: 1;
  display: flex;
  justify-content: center;
  text-align: center;
  padding-bottom: 0;
}
.footer-section-columns {
  display: flex;
  flex-direction: column;
  min-width: 190px;
  
}
.footer-section-columns :link{
text-decoration: none;
color: #FFF;
}
.footer-section-columns span {
  margin: 1.5rem 0rem;
  font-size: 1rem;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}
.footer-section-columns img {
  max-width: 150px;
}

.footer-section-columns .contatss {
  color: var(--amarelodecisao);
  font-weight: 600;
  font-size: 1rem;
  margin-top: 60px;
  margin-left: 200px;
  text-decoration: none;
}
.footer-section-columns .contatss2 {
  font-weight: 200;
  font-size: .8rem;
  margin-top: -10px;
  margin-left: 200px;
  text-decoration: none;
}

.contatssocial {
  display: flex;
  align-items: center; /* Alinha verticalmente os itens */
  margin-bottom: .5rem;
}

.contatssocial a {
  margin-right: -10px; /* Espaço entre o ícone e o texto */
  color: #fff; /* Cor do ícone */
  font-size: .1rem; /* Tamanho do ícone */
}


@media(max-width: 768px) {
  .containerForm, .containerFormAtuacao {
    width: 80%;
    margin-bottom: -100px;
  }
  .about-text p, .atuacao-text p{
    width: 100%;
    text-align:justify;
}
#about, #atuacao{
  height: 850px;
}
.about-text h1{
  margin-top: -100px;
}

.about-text h1, .atuacao-text h1{
  color:var(--basePrimary);
  
}


  .formBox, .formBoxAtuacao {
    padding: 40px 40px;
  }

  .formBox p, .formBoxAtuacao p {
    font-size: 14px;
  }

  .formBox input[type="text"],
  .formBox input[type="tel"],
  .formBox input[type="email"],
  .formBoxAtuacao input[type="text"],
  .formBoxAtuacao input[type="tel"],
  .formBoxAtuacao input[type="email"] {
    height: 35px;
  }

  .formBox input[type="submit"], .formBoxAtuacao input[type="submit"] {
    height: 35px;
  }
  #foot {
    position: relative;
 }
 #main{
  position: relative;
  height:750px;
 }
 #main::before{
  position: relative;
  height:750px;
 }
 #main::after {
  position: relative;
  height:750px;
 }
 .name{
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
  width: auto;
  
  
}
.name h2{
  font-size: 1.4rem;
  text-align: center;
  width: 700px;
}
.name span{
font-size: 1.5rem;
text-align: center;
}
 .containerForm .left, .containerFormAtuacao .left {
  display: none;
}

.containerForm .right, .containerFormAtuacao .right {
  width: 100%; /* Expande a parte direita para ocupar toda a largura */
  
  padding: 0;
  margin: 0;
}
.about-imagebranco{
  display: none;
}
#presentaion{
  padding: 5px;
  margin: 0px;
  height:640px;
  
}
.footer-section-columns .contatss {
  color: var(--dourada);
  font-weight: 600;
  font-size: 1rem;
  margin-top: 60px;
  margin-left: 0px;
}
.footer-section-columns .contatss2 {
  font-weight: 200;
  font-size: .8rem;
  margin-top: -10px;
  margin-left: 0px;
}
.footer-section-columns :link{
  text-decoration: none;
  color: #FFF;
  }
#atuacao {
  flex-direction: column; /* Alinha o texto e o formulário em coluna no mobile */
}

.atuacao-text, .atuacao-contato {
  flex: none;
  width: 100%; /* Ocupar toda a largura no mobile */
  margin: 0; /* Remove qualquer margem */
}
.about-image{
  display: none;
}

.atuacao-contato {
  margin-top: 10px; /* Adiciona espaço entre o texto e o formulário */
  margin-left: 5px;
}
.slide img{
  height: 900px;
  width: 200%;
  margin-left: -210px;
}
.slides{
  height: 900px;
}
.navigation-auto{
  margin-top: 900px;
}
#main{
  height: 900px;
}

nav .menu-btn:checked ~ .menu {
  display: block;
}
nav .menu li a {
  cursor: pointer;
}

.ZapBox input{
  width: 50%;
  
}



}

@media(max-width: 1024px) {
  .containerForm, .containerFormAtuacao {
    width: 80%;
    margin-bottom: -100px;
  }
  .about-text p, .atuacao-text p{
    width: 100%;
    text-align:justify;
    text-align: center;
}
#about, #atuacao{
  height: 850px;
}
.about-text h1, .atuacao-text h1{
  color:var(--basePrimary);
  font-size: 1.5rem;
}


  .formBox, .formBoxAtuacao {
    padding: 40px 40px;
  }

  .formBox p, .formBoxAtuacao p {
    font-size: 14px;
  }
  .atuacao-image{
    display: none;
  }

  .formBox input[type="text"],
  .formBox input[type="tel"],
  .formBox input[type="email"],
  .formBoxAtuacao input[type="text"],
  .formBoxAtuacao input[type="tel"],
  .formBoxAtuacao input[type="email"] {
    height: 35px;
  }
  .footer-icons{
    display: none;
  }

  .formBox input[type="submit"], .formBoxAtuacao input[type="submit"] {
    height: 35px;
  }
  #foot {
    position: relative;
 }
 #main{
  position: relative;
  height:930px;
 }
 #main::before{
  position: relative;
  height:930px;
 }
 #main::after {
  position: relative;
 }
 .name{
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
  width: auto;
  width: 1024px;
}
.name h2{
  font-size: 1.8rem;
  text-align: center;
}
.name span{
font-size: 1.8rem;
text-align: center;
}
 .containerForm .left, .containerFormAtuacao .left {
  display: none;
}

.containerForm .right, .containerFormAtuacao .right {
  width: 100%; /* Expande a parte direita para ocupar toda a largura */
  
  padding: 0;
  margin: 0;
}
.about-imagebranco{
  display: none;
}
#presentaion{
  padding: 5px;
  margin: 0px;
  height:640px;
  
}
.ZapBox input{
  width: 70%;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0);
}
.footer-section-columns .contatss {
  color: var(--dourada);
  font-weight: 600;
  font-size: 1rem;
  margin-top: 60px;
  margin-left: 0px;
}
.footer-section-columns .contatss2 {
  font-weight: 200;
  font-size: .8rem;
  margin-top: -10px;
  margin-left: 0px;
}
.footer-section-columns :link{
  text-decoration: none;
  color: #FFF;
  }
#atuacao {
  flex-direction: column; /* Alinha o texto e o formulário em coluna no mobile */
  align-items: center;
}

.atuacao-text, .atuacao-contato {
  flex: none;
  width: 100%; /* Ocupar toda a largura no mobile */
  margin: 0; /* Remove qualquer margem */
}

.atuacao-contato {
  margin-top: 10px; /* Adiciona espaço entre o texto e o formulário */
  margin-left: 5px;
  display: flex;
    justify-content: center;
}
.containerFormAtuacao {
  max-width: 500px; /* Limita a largura do formulário no centro */
  width: 100%;
  padding: 10px;
}




}

@media(max-width: 1280px) {
  .containerForm, .containerFormAtuacao {
    width: 80%;
    margin-bottom: -100px;
  }
  .about-text p, .atuacao-text p{
    width: 100%;
    text-align:justify;
}
#about, #atuacao{
  height: 850px;
}
.about-text h1, .atuacao-text h1{
  color:var(--basePrimary);
  
}


  .formBox, .formBoxAtuacao {
    padding: 40px 40px;
  }

  .formBox p, .formBoxAtuacao p {
    font-size: 14px;
  }
  .atuacao-image{
    display: none;
  }

  .formBox input[type="text"],
  .formBox input[type="tel"],
  .formBox input[type="email"],
  .formBoxAtuacao input[type="text"],
  .formBoxAtuacao input[type="tel"],
  .formBoxAtuacao input[type="email"] {
    height: 35px;
  }
  .footer-icons{
    display: none;
  }

  .formBox input[type="submit"], .formBoxAtuacao input[type="submit"] {
    height: 35px;
  }
  #foot {
    position: relative;
 }
 #main{
  position: relative;
  height:930px;
 }
 #main::before{
  position: relative;
  height:930px;
 }
 #main::after {
  position: relative;
 }
 .name{
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
  width: auto;
  width: 1200px;
}
.name h2{
  font-size: 1.8rem;
  text-align: center;
}
.name span{
font-size: 1.8rem;
text-align: center;
}
 .containerForm .left, .containerFormAtuacao .left {
  display: none;
}

.containerForm .right, .containerFormAtuacao .right {
  width: 100%; /* Expande a parte direita para ocupar toda a largura */
  
  padding: 0;
  margin: 0;
}
.about-imagebranco{
  display: none;
}
#presentaion{
  padding: 5px;
  margin: 0px;
  height:640px;
  
}
.footer-section-columns .contatss {
  color: var(--dourada);
  font-weight: 600;
  font-size: 1rem;
  margin-top: 60px;
  margin-left: 0px;
}
.footer-section-columns .contatss2 {
  font-weight: 200;
  font-size: .8rem;
  margin-top: -10px;
  margin-left: 0px;
}
.footer-section-columns :link{
  text-decoration: none;
  color: #FFF;
  }
#atuacao {
  flex-direction: column; /* Alinha o texto e o formulário em coluna no mobile */
  align-items: center;
}

.atuacao-text, .atuacao-contato {
  flex: none;
  width: 100%; /* Ocupar toda a largura no mobile */
  margin: 0; /* Remove qualquer margem */
}

.atuacao-contato {
  margin-top: 10px; /* Adiciona espaço entre o texto e o formulário */
  margin-left: 5px;
  display: flex;
    justify-content: center;
}
.containerFormAtuacao {
  max-width: 500px; /* Limita a largura do formulário no centro */
  width: 100%;
  padding: 10px;
}




}



@media(max-width: 935px) {
  .containerForm, .containerFormAtuacao {
    width: 30%;
    margin: 90px 60px;
  }
  
}




@media(max-width:620px){
  #contact form{
      width:90%;
  }
  .formBox input[type="button"], .formBoxAtuacao input[type="button"]
{
  display: flex;
  margin-top: -30px;
  padding: 0;
}
  .footer-section-center{
    display: none;
    
  }
  .footer-section-one{
    
    flex: 1;
  display: absolute;
  justify-content: center;
  text-align: center;
  padding-left: 0px;
  min-width: 390px;
    
  }
  .footer-section-two{
display: none;
  }
  .a-box{
    border: 1px ridge var(--basePrimary);
    box-shadow: 2px 2px 12px var(--basePrimary);
    
  }
  .a-b-img img{
    padding: 0px;
   
  
  }
  
}

@media(min-width:1100px) and (max-width:1366px){
  .about-image img{
    max-height:550px;
    width: 500px;
}
#about, #atuacao{
  height: 110vh;
}

}

@media(max-width:1366px){
  #main{
      background-size: 1360px !important;
      
      
  }
  .name{
      left: 50%;
      top: 45%;
      transform: translate(-50%,-50%);
      text-align: center;
      width: auto;
      width: 1300px;
  }
  .name h2{
      
      text-align: center;
  }
  .name span{
    
    text-align: center;
}
  .name h1{
      font-size: 3rem;
  }
  .name .details{
      
  }
  .pr-heading{
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      text-align: center;
  }
  .pr-heading h2{
      font-size: 1.5rem;
  }
  .pr-heading h1{
      font-size: 3rem;
  }
  .pr-heading .details{
      font-size: 1rem;
  }
  .about-image img{
      height:550px;
      width: 450px;
  }
  #features{
      height:auto;
  }
  #features h1{
    font-size: 4rem;
  }
  .a-container{
      flex-wrap: wrap;
  }
  .a-box{
      flex-grow: 1;
  }
  .a-b-img img{
      object-fit:contain;
  }

  
  .about-text h1, .atuacao-text h1{
    font-size: 2.4rem;
    
    font-weight: 600;
    margin: 0px;
    padding: 0px;
  }
  .about-text p, .atuacao-text p{
    width: 80%;
    font-size: 1rem;
    color:var(--textSecundary);
    text-align: justify;
  }
  .containerForm, .containerFormAtuacao{
    width: 80%;
    
    max-width: 1000px;
    align-items: center;
    margin-right: 45px;
    top: 50%;
      
    
  }
  .containerFormAtuacao{
    width: 100%;
    max-width: 1300px;
  }
  .footer-section-columns .contatss {
    color: var(--dourada);
    font-weight: 600;
    font-size: 1rem;
    margin-top: 60px;
    margin-left: 0px;
  }
  .footer-section-columns .contatss2 {
    font-weight: 200;
    font-size: .8rem;
    margin-top: -10px;
    margin-left: 0px;
  }
  .footer-section-columns :link{
    text-decoration: none;
    color: #FFF;
    }

}



@media(max-width:970px){
  #main{
      height: 896px;
  }
  .name{
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      width: 970px;
      width: auto;
  }
  .pr-heading{
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
  }
  .about-image, .atuacao-image{
      display:none;
  }
  .header-btn{
      margin-left: 20%;
      margin-top: 20px;
  }
  .pr-btn{
      margin-left: 20%;
      margin-top: 20px;
  }
  #about, #atuacao{
      justify-content: center;
      padding-top: 0px;
      text-align: center;
      
      margin-top: 0px;
  }
  #atuacao{
    
    padding-top: 300px;
    
    
    margin-top: 0px;
}
  .about-text, .atuacao-text{
      width: 90%;
  }
  .about-text h1{
      font-size:4rem;
  }
  .about-text p, .atuacao-text p{
      width: 100%;
  }
  #features{
      margin-bottom: 0px;
  }
}
@media (min-width:360px)and(max-width:390px) {
  .name h2{
    
    width: 380px;
  }

}
@media (max-width:412px) {
  .name h2{
    
    width: 412px;
  }

}
@media(min-width:360px) and (max-width:430px){
  
  .slide img{
    
    align-items: center;
  }
  
  .name h2{
    font-size: 1.2rem;
    width: 360px;
  }
  .name span{
    font-size: 1.6rem;
  }
  .name .details{
    font-size: 13px;
  }
}

@media(max-width:600px){
  .name{
      width:auto;
      
  }
  
  .pr-heading{
      width:60%;
  }
  #footer-icons{
    display: none;
  }
  nav{
    width: 100%;
  }
}


@media(min-width:768px)and(max-width:1100px){
  .name h2{
    width: 800px;
  }
}

@media(max-width:1100px){
  .name{
    width: auto;
  }
  
  .menu-icon{
  display:block;
}
      nav{
          justify-content: space-between;
          height: 85px;
          padding: 0px 30px;
      }
      .logo img{
          width:112px;
          margin-left: 0;
          margin-top: 5px;
      }
      .header-btns{
          margin: 0;
      }
      .pr-btns{
          margin: 0;
      }
      .menu{
          display:none;
          position:absolute;
          top:85px;
          left:0px;
          background-color:var(--basePrimary);
          border-bottom:4px solid var(--dourada);
          width:100%;
          padding:0px;
          margin:0px;
          z-index: 10;
      }
      .menu:hover{
        background-color: var(--basePrimary);
      }
      .menu li{
          width:100%;
      }
      nav .menu li a{
          width:100%;
          height:40px;
          justify-content: center;
          align-items: center;
          margin:0px;
          padding: 25px;
          border:1px solid rgba(38,38,38,0.03);
      }
      nav .menu-icon{
          cursor:pointer;
          float:right;
          padding: 28px 20px;
          position:relative;
          user-select: none;
      }
      nav .menu-icon .nav-icon{
          background-color:#000000;
          display:block;
          height:2px;
          position:relative;
          transition: background 0.2s ease-out;
          width:24px;
      }
      nav .menu-icon .nav-icon:before,
      nav .menu-icon .nav-icon:after{
          background:#000000;
          content:'';
          display:block;
          height:100%;
          position:absolute;
          transition:all ease-out 0.2s;
          width:100%;
      }
      nav .menu-icon .nav-icon:before{
          top:6px;
      }
      nav .menu-icon .nav-icon:after{
          top:-6px;
      }
      nav .menu-btn:checked ~ .menu-icon .nav-icon{
          background:transparent;
      }
      nav .menu-btn:checked ~ .menu-icon .nav-icon:before{
          transform: rotate(-45deg);
          top:0;
      }
      nav .menu-btn:checked ~ .menu-icon .nav-icon:after{
          transform: rotate(45deg);
          top:0;
      }
      nav .menu-btn{
          display:none;
      }
      nav .menu-btn:checked ~ .menu{
          display:block;
      }
      .dropdown-menu {
        min-width: 100%; /* Garante que o dropdown ocupe toda a largura disponível */
        left: 0;
        box-shadow: none; /* Remover sombra em telas menores */
      }
    
      .dropdown-menu li {
        
        margin: 10px;
      }
      .dropdown-menu li a::before {
        content: none;
         /* Bordas arredondadas da linha */
      }
    
      .dropdown:hover .dropdown-menu {
        opacity: 1;
        transform: translateY(0);
        pointer-events: auto;
      }

    
    }
      
      @media(max-width: 935px) {
        .containerForm, .containerFormAtuacao {
           width: 90%;
           margin: 30px auto;
        }
     }
     @media(max-width: 390px) {
        .containerForm, .containerFormAtuacao {
           width: 100%;
           margin: 20px auto;
           align-items: center;
           
        }
        #foot {
          position: relative;
       }
       #main{
        position: relative;
        height:900px;
       }
       
     }
     @media (min-width: 400px) and (max-width: 450px) {
      .containerForm, .containerFormAtuacao {
         width: 100%;
         
         margin-right: 10px;
      }
      
     
   }
   @media(max-width: 375px) {
    .footer-section-columns .contatss {
      color: var(--dourada);
      font-weight: 600;
      font-size: 0.8rem;
      margin-top: 60px;
      margin-left: 0px;
    }
    #main{
    width: 100%;
    }
    .name{
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      text-align: center;
      width: auto;
    }
    .name h2{
      font-size: 1.1rem;
      text-align: center;
      width: 360px;
    }
    .name span{
    font-size: 1.3rem;
    text-align: center;
    }

    .containerForm, .containerFormAtuacao {
      width: 100%;
      
    }
    .about-text p, .atuacao-text p{
      width: 100%;
      text-align:justify;
  }
  #about, #atuacao{
    height: 850px;
  }
  .about-text h1, .atuacao-text h1{
    color:var(--basePrimary);
 
    font-size: 2.2rem;
  }
  .about-text p, .atuacao-text p{
    
    font-size: 1rem;
    
   
  }
   }
   @media(min-width:320px)and(max-width: 360px) {
    .name{
      width: auto;
    }
    .name h2{
      width: 350px;
      font-size: 0.6rem;
    }
    .name span{
      font-size: 1.2rem;
    }
    .name .details{
      font-size: 12px;
    }
   }